import React from "react";
import './App.css';
import Header from './components/Header/Header';
import Banner from "./components/Banner/Banner";
import About from "./components/About/About";
import Technology from "./components/Technology/Technology";
import Freelas from "./components/Freelas/Freelas";
import Portfolio from "./components/Portfolio/Portfolio";
import Form from "./components/Form/Form";
import Footer from "./components/Footer/Footer";
import Copyright from "./components/Copyright/Copyright";
import HeaderMobile from "./components/Header-mobile/Header-mobile";

export default () => {

    return (
        <div className="page">
                <Header />
                <HeaderMobile/>
                <Banner />
                <About/>
                <Technology/>
                <Freelas/>
                <Portfolio/>
                <Form/>
                <Footer/>
                <Copyright/>
        </div>
    )

}