import React, { useRef, useState } from "react";
import './Portfolio.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Thebueno from '../Imagens/thebueno.png';
import Embreve from '../Imagens/pag-embreve.jpg';
import Netflix from '../Imagens/netflix.jpg';

export default () => {
    return (
        <Container fluid>
            <Row>
                <Col md="8" className="contained-slider" id="portfolio">
                    <Swiper pagination={true} navigation={true} modules={[Navigation, Pagination]} className="mySwiper">
                        <SwiperSlide>
                            <Row className="slider">
                                <Col md="6" className="img-slider">
                                    <img src={Thebueno} />
                                </Col>
                                <Col md="6" className="text-slider">
                                    <h2>The Bueno's Home Chef</h2>
                                    <p>Site institucional com o intuito de promover um serviço de churrasco</p>
                                    <a href="https://www.behance.net/gallery/127785649/Site-institucional-Churrasco-a-domicilio" target="_blank">
                                        <button>Demo</button>
                                    </a>
                                </Col>
                            </Row>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Row className="slider">
                                <Col md="6" className="img-slider">
                                    <img src={Embreve} />
                                </Col>
                                <Col md="6" className="text-slider">
                                    <h2>Landing Page</h2>
                                    <p>Modelo de uma página em breve, construido em HTML e CSS.</p>
                                    <a href="https://www.behance.net/gallery/147697985/Pagina-em-breve-LP" target="_blank">
                                        <button>Demo</button>
                                    </a>
                                </Col>
                            </Row>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Row className="slider">
                                <Col md="6" className="img-slider">
                                    <img src={Netflix} />
                                </Col>
                                <Col md="6" className="text-slider">
                                    <h2>Clone Netflix - React.Js</h2>
                                    <p>Um Clone da Netflix para fins acadêmicos, projeto desenvolvido em React.js com manipulação da API TMDB</p>
                                    <a href="https://github.com/mahsousa/clone-netflix" target="_blank">
                                        <button>Demo</button>
                                    </a>
                                </Col>
                            </Row>
                        </SwiperSlide>
                    </Swiper>
                </Col>
                <Col md="4" className="portfolio-about">
                    <h2 className="technology-title">PORTFÓLIO</h2>
                    <div id="linha-vertical"></div>
                    <p className="text">Meus <br /> últimos <br /> trabalhos</p>
                </Col>
            </Row>
        </Container>
    )
}
