import React from 'react';
import './Header.css';
import Logo from '../Imagens/Logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default () => {
    return (
        <Container fluid className='content-header p-1'>
            <Container>
                <Row>
                    <Col md='2' className="d-flex justify-content-center align-items-center">
                        <div className='logo'>
                            <img className='img-logo' src={Logo} />
                        </div>
                    </Col>
                    <Col md='8' className="d-flex justify-content-center align-items-center p-0
                m-0">
                        <div className='menu'>
                            <ul>
                                <li>
                                    <a href="#">HOME</a>
                                </li>
                                <li>
                                    <a href="#sobre">SOBRE</a>
                                </li>
                                <li>
                                    <a href="#tec">TECNOLOGIAS</a>
                                </li>
                                <li>
                                    <a href="#freelancer">FREELANCER</a>
                                </li>
                                <li>
                                    <a href="#portfolio">PORTFÓLIO</a>
                                </li>
                                <li>
                                    <a href="#contato">CONTATO</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md='2' className="d-flex justify-content-center align-items-center p-0
                m-0">
                        <div className="button-header" href="#contato">
                            <a href="#contato">
                                <button>CONTATO</button>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}