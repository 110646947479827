import React from "react";
import './About.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default () => {
    return (
        <Container fluid className="content-about" id="sobre">
            <Container>
                <Row className="content">
                    <Col md="12">
                        <div className="title-about">
                            <h2>UM POUQUINHO <span>SOBRE</span> MIM</h2>
                        </div>
                        <div className="about">
                            <p>Sou uma desenvolvedora Front-End há 5 ANOS, que está sempre desenvolvendo projetos
                                e ama participar de maratonas e bootcamps, amo musica, atualmente sei tocar 5
                                instrumentos musicas e tenho como hobbe visitar lugares novos,
                                um exemplo são parques, restaurantes e lugares que me desperte a curiosidade. </p>
                        </div>
                    </Col>
                    <Col md="12">
                        <Row className="experience">
                            <Col md="4">
                                <p><span>8+ </span> <br />Anos de <br />estudo</p>
                            </Col>
                            <Col md="4">
                                <p><span>4+ </span> <br /> Anos de <br />experiência</p>
                            </Col>
                            <Col md="4">
                                <p><span>Muitos </span><br />Projetos<br />concluídos</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}