import React, { useState } from "react";
import './Form.css';
import emailjs from '@emailjs/browser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Github from '../Imagens/github.png';
import Outlook from '../Imagens/outlook.png';
import Linkedin from '../Imagens/linkedin.png';



function App() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [project, setProject] = useState('')
    const [msg, setMsg] = useState('')

    function sendEmail(e) {
        e.preventDefault();

        if (name === '' || email === '' || project === '' || msg === '') {
            alert("Preencha todos os campos");
            return;
        }

        const templateParams = {
            from_name: name,
            email: email,
            project: project,
            msg: msg

        }

        emailjs.send("service_8phk1kg", "template_0umjdhd", templateParams, "BvpGDsej73rDaygaN")
            .then((result) => {
                alert("Mensagem enviada com sucesso");
            }, (error) => {
                alert("Ocorreu um erro");
            });
    }

    return (
        <Container fluid className="content-form" id="contato">
            <Container>
                <Row>
                    <Col md="12" className="title-form">
                        <h2>CONTATO</h2>
                        <p>Vamos bater um papo?</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="icones-sociais">
                        <ul>
                            <li>
                                <a href="https://github.com/mahsousa" target="_blank">
                                    <img src={Github} />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/maria-sousa-41a14221a/" target="_blank">
                                    <img src={Linkedin} />
                                </a>
                            </li>
                            <li>
                                <a href="mailto:contato@mariasousa.dev.br">
                                    <img src={Outlook} />
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md="8" className="form">
                        <Form onSubmit={sendEmail}>
                            <Row>
                                <Col className="form-up">
                                    <Form.Group className="mb-5 name" controlId="formBasicName">
                                        <Form.Control type="text" placeholder="Nome:" onChange={(e) => setName(e.target.value)} value={name} />
                                    </Form.Group>
                                    <Form.Group className="mb-5 email" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="E-mail:" onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-5 project" controlId="formBasicProject">
                                <Form.Control type="text" placeholder="Qual o seu projeto?" onChange={(e) => setProject(e.target.value)} value={project} />
                            </Form.Group>
                            <Form.Group className="mb-5 msg" controlId="formBasicMsg">
                                <Form.Control type="text" placeholder="Qual a sua mensagem?" onChange={(e) => setMsg(e.target.value)} value={msg} />
                            </Form.Group>
                            <Button type="submit">
                                Enviar
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default App;