import React from 'react';
import './Header-mobile.css';
import Logo from '../Imagens/Logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Menu from '../Imagens/menu-hamburguer.png';


export default () => {

    function menuShow() {
        let menuMobile = document.querySelector('.menu-mobile');
        if (menuMobile.classList.contains('open')) {
            menuMobile.classList.remove('open');
        } else {
            menuMobile.classList.add('open');
        }
        let show = true;

        menuMobile.addEventListener("click", (event) => {
            event.stopPropagation();

            show = !show;

            if (event.target.tagName === 'A' && !show)
                menuMobile.classList.remove('open');
        })
    }

    return (
        <Container fluid className='content-menu-mobile'>
            <Row>
                <Col md="6" className='logo-mobile'>
                    <div>
                        <img src={Logo} />
                    </div>
                </Col>
                <Col md="6" className='mobile-menu-icon'>
                    <button onClick={menuShow}><img src={Menu} /></button>
                    <Row className="menu-mobile">
                        <Col md="12">
                            <div >
                                <ul>
                                    <li>
                                        <a href="#">HOME</a>
                                    </li>
                                    <li>
                                        <a href="#sobre">SOBRE</a>
                                    </li>
                                    <li>
                                        <a href="#tec">TECNOLOGIAS</a>
                                    </li>
                                    <li>
                                        <a href="#freelancer">FREELANCER</a>
                                    </li>
                                    <li>
                                        <a href="#portfolio">PORTFÓLIO</a>
                                    </li>
                                    <li>
                                        <a href="#contato">CONTATO</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}