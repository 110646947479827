import React from "react";
import './Technology.css';
import react from '../Imagens/react.png';
import JavaScript from '../Imagens/javascript.png';
import CMS from '../Imagens/cms.png';
import CSS from '../Imagens/css.png';
import Node from '../Imagens/node.png';
import Git from '../Imagens/git.png';
import HTML from '../Imagens/html.png';
import Bootstrap from '../Imagens/bootstrap.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default () => {
    return (
        <Container fluid className="content-technology" id="tec">
            <Row >
                <Col md="4" className="technology-about">
                    <h2 className="technology-title">TECNOLOGIAS</h2>
                    <div id="linha-vertical"></div>
                    <p className="text">Essas são <br />as tecnologias <br />que eu uso</p>
                </Col>
                <Col md="8" className="lists">
                    <Row>
                        <Col md="12">
                            <Row>
                                <Col md="3" className="list-one">
                                    <img src={react} title="React"/>
                                </Col>
                                <Col md="3" className="list-one">
                                    <img src={JavaScript} title="JavaScript"/>
                                </Col>
                                <Col md="3" className="list-one">
                                    <img src={Bootstrap} title="Bootstrap"/>
                                </Col>
                                <Col md="3" className="list-one">
                                    <img src={HTML} title="HTML"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3" className="list-one">
                                    <img src={CSS} title="CSS"/>
                                </Col>
                                <Col md="3" className="list-one">
                                    <img src={Node} title="Node"/>
                                </Col>
                                <Col md="3" className="list-one">
                                    <img src={CMS} title="CMS"/>
                                </Col>
                                <Col md="3" className="list-one">
                                    <img src={Git} title="Git"/>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}