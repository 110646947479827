import React from "react";
import './Banner.css';
import Maria from '../Imagens/Maria.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default () => {

    var i = 0;
    // var A = 0;
    var tag;
    var html;
    var attr;
    var txt;
    var speed = 170;

    const typeWriter = () => {

        if (i == 0) {
            tag = document.getElementById("text");
            html = document.getElementById("text").innerHTML;
            attr = tag.setAttribute("data", html);
            txt = tag.getAttribute("data");
        }

        if (i <= txt.length) {
            document.getElementById("text").innerHTML = txt.slice(0, i + 1);
            i++;
            setTimeout(typeWriter, speed);
        }
        //console.log(document.getElementById("text").innerHTML);
    }

    return (
        <Container fluid className="content-banner" onLoad={() => typeWriter()}>
            <Container >
                <Row >
                    <Col md='6' className="info-banner" >
                        <div className="info typewriter">
                            <h2 className="sobre" id="text"> Oi meu nome é Maria Sousa </h2>
                            <p className="profissao" > Desenvolvedora Front - End </p>
                            <p className="descricao" > Desenvolvedora front - end que ama musica, visitar lugares novos no tempo livre e a sua doguinha Safira. </p>
                        </div >
                    </Col>
                    <Col md='6' className="img-banner" >
                        <div className="img-maria" >
                            <img src={Maria} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}