import React from "react";
import './Freelas.css';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function BasicCard() {
    return (
        <Container fluid className="content-freelas" id="freelancer">
            <Container className="cont-freelas">
                <Row>
                    <Col md="12">
                        <div className="title">
                            <h2>FREELANCER</h2>
                            <p>Esses são os serviços que eu ofereço</p>
                        </div>
                    </Col>
                    <Col md="12">
                        <Row className="card-list">
                            <Col md="3" className="card">
                                <div className="icon">
                                    <WebAssetIcon style={{ fontSize: 80 }} />
                                </div>
                                <div id="linha-horizontal"></div>
                                <div className="typography">
                                    <span>Site <br /> Institucional</span>
                                </div>
                            </Col>
                            <Col md="3" className="card">
                                <div className="icon">
                                    <ContentCopyIcon style={{ fontSize: 80 }} />
                                </div>
                                <div id="linha-horizontal"></div>
                                <div className="typography">
                                    <span>Landing <br /> Pages</span>
                                </div>
                            </Col>
                            <Col md="3" className="card">
                                <div className="icon">
                                    <BrushOutlinedIcon style={{ fontSize: 80 }} />
                                </div>
                                <div id="linha-horizontal"></div>
                                <div className="typography">
                                    <span>Design e <br />Papelaria</span>
                                </div>
                            </Col>
                            <Col md="3" className="card">
                                <div className="icon">
                                    <ShoppingCartOutlinedIcon style={{ fontSize: 80 }} />
                                </div>
                                <div id="linha-horizontal"></div>
                                <div className="typography">
                                    <span>Loja<br />Virtual</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}