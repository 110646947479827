import React from "react";
import './Copyright.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../Imagens/Logo.png';

export default () => {
    return (
        <Container fluid className="footer-copyright">
            <Container>
                <Row >
                    <Col md="12" className="text-copyright">
                        <p>Maria Sousa © 2022 | Todos os direitos reservados</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}