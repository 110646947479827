import React from "react";
import './Footer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../Imagens/Logo.png';

export default () => {
    return (
        <Container fluid className="content-footer">
            <Container>
                <Row className="footer">
                    <Col md="3">
                        <div className='logo '>
                            <img src={Logo} />
                        </div>
                    </Col>
                    <Col md="3" className="menu-footer">
                        <h2>NAVEGAÇÃO</h2>
                        <div className="linha-horizontal" id="linha-horizontal"></div>
                        <a href="#sobre"><p>Sobre</p></a>
                        <a href="#tec"><p>Tecnologias</p></a>
                        <a href="#freelancer"><p>Freelancer</p></a>
                        <a href="#portfolio"><p>Portfólio</p></a>
                    </Col>
                    <Col md="3">
                        <h2>CONTATO</h2>
                        <div id="linha-horizontal"></div>
                        <p>contato@mariasousa.dev.br</p>
                    </Col>
                    <Col md="3">
                        <h2>SERVIÇOS</h2>
                        <div id="linha-horizontal"></div>
                        <p>Site Institucional</p>
                        <p>Landing Page</p>
                        <p>Loja Virtual</p>
                        <p>Design e Papelaria</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}